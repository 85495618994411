import React from "react";
import { motion } from "framer-motion";
import "../styles/home.scss";
import HomeCard from "./HomeCard";
import Lottie from "react-lottie";
import animationData from "../lottie/63921-developer.json";
import { Hidden } from "react-grid-system";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Button } from "@material-ui/core";

library.add(faGithub, faLinkedin);
const Home = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "",
    },
  };
  return (
    <div className="Home">
      <div className="front">
        {/* <img src={Homesvg} alt="" className="home_svg" /> */}
        <Hidden sm xs>
          <motion.span
            initial={{ opacity: 0, y: -100 }}
            transition={{ duration: 1, type: "spring", stiffness: "200" }}
            animate={{ opacity: 1, y: 0 }}
            className="homeSvg"
          >
            <Lottie options={defaultOptions} height={500} width={500} />
          </motion.span>
        </Hidden>
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          transition={{ duration: 1 }}
          animate={{ opacity: 1, y: 0 }}
          className="intro"
        >
          <motion.p className="Hi_tag subtitles">Hello World,</motion.p>
          <motion.p className="Hi_tag subtitles">I am</motion.p>

          <motion.h1
            initial={{ x: -100 }}
            transition={{ duration: 1.5, type: "spring" }}
            animate={{ x: 0 }}
            className="Name"
          >
            PRIYANSHU<span style={{ color: "#7350E6" }}></span>
          </motion.h1>
          <motion.p className="work_tag subtitles">A Web Developer</motion.p>

          <motion.p
            initial={{ opacity: 0, y: -100 }}
            transition={{ duration: 1, type: "spring", stiffness: "200" }}
            animate={{ opacity: 1, y: 0 }}
            className="contact_P"
            style={{ display: "flex", marginTop: "1rem" }}
          >
            <motion.a
              whileHover={{ scale: 1.2, rotate: 0 }}
              whileTap={{
                scale: 0.8,
                rotate: 360,
                borderRadius: "50%",
              }}
              href="https://www.linkedin.com/in/priyanshu-kumar-6b396b192/"
              target="_blank"
              style={{ marginRight: "1rem" }}
            >
              <FontAwesomeIcon
                className="LinkedIn_icon"
                icon={["fab", "linkedin"]}
                size="2x"
              />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.2, rotate: 0 }}
              whileTap={{
                scale: 0.8,
                rotate: 360,
                borderRadius: "50%",
              }}
              href="https://github.com/i-priyanshu"
              target="_blank"
              style={{ marginRight: "1rem" }}
            >
              <FontAwesomeIcon
                className="Github_Icon"
                icon={["fab", "github"]}
                size="2x"
              />
            </motion.a>
            <Button
              href="https://drive.google.com/file/d/1fBNtbWNO4yHiet_1lMdrluESgogSyqRB/view?usp=sharing"
              target="_blank"
              variant="outlined"
              color="secondary"
            >
              Resume
            </Button>
          </motion.p>
        </motion.div>
      </div>
      <HomeCard />
    </div>
  );
};

export default Home;
